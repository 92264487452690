/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionProductType } from './transactionProductType';


/**
 * Transaction Product resource
 */
export interface TransactionProductResource { 
    /**
     * ID
     */
    id?: number;
    /**
     * ID of the transaction
     */
    transaction_id?: number;
    /**
     * Name of the product
     */
    name?: string;
    type?: TransactionProductType;
    /**
     * ID of the product
     */
    product_id?: number;
    /**
     * Price of the product
     */
    price?: number;
    /**
     * Quantity of the product
     */
    quantity?: number;
    /**
     * Base64 representation of the product image
     */
    image_base64?: string;
    /**
     * Description of the product
     */
    desc?: string;
}
export namespace TransactionProductResource {
}


