/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionResource } from './transactionResource';
import { RodoRequestStatus } from './rodoRequestStatus';


/**
 * RodoRequestResource
 */
export interface RodoRequestResource { 
    status?: RodoRequestStatus;
    desc?: string;
    rejection_reason?: string;
    /**
     * Created at
     */
    created_at?: string;
    /**
     * Transactions
     */
    transactions?: Array<TransactionResource>;
}
export namespace RodoRequestResource {
}


