/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Paymentwall Shop Type resource
 */
export interface PaymentwallShopTypeResource { 
    /**
     * Unique identifier for the Paymentwall Shop Type
     */
    id?: number;
    /**
     * Label of the Paymentwall Shop Type
     */
    label?: string;
}

