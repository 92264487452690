/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeeParameterDefinitionResource } from './feeParameterDefinitionResource';


/**
 * Fee resource
 */
export interface ShopFeeResource { 
    /**
     * ID
     */
    id?: number;
    /**
     * Name of the fee
     */
    name?: string;
    transaction_limit?: number | null;
    transaction_count?: number | null;
    /**
     * Discount text associated with the fee
     */
    discount_text?: string | null;
    /**
     * Monthly price of the fee
     */
    monthly_price?: number;
    /**
     * Fee percent
     */
    fee_percent?: number | null;
    /**
     * Micro fee
     */
    micro_fee?: number | null;
    /**
     * Description of the fee
     */
    desc?: string | null;
    /**
     * Date when fee becomes active
     */
    valid_from?: string;
    /**
     * Date when fee becomes inactive
     */
    valid_to?: string | null;
    /**
     * Fee Parameter Definitions associated with the Fee
     */
    parameters?: Array<FeeParameterDefinitionResource> | null;
}

