/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Shop saldo resource
 */
export interface ShopSaldoResource { 
    /**
     * Date of update
     */
    date_of_update?: string;
    /**
     * Currency
     */
    currency?: string;
    /**
     * Amount
     */
    amount?: number;
    /**
     * Fee
     */
    total_fees?: number;
    /**
     * Gate fee
     */
    gate_fees?: number;
    /**
     * Eself micro fee
     */
    eself_fees?: number;
    /**
     * Eself micro fee
     */
    micro_fees?: number;
    /**
     * Withdrawal
     */
    withdrawal?: number;
    /**
     * Available balance
     */
    available_balance?: number;
    /**
     * Invoice id
     */
    invoice_id?: number;
}

