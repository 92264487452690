/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Invoice Request body data
 */
export interface UpdateInvoiceRequest { 
    /**
     * Invoice number
     */
    invoice_number?: string;
    /**
     * Invoice issue date
     */
    issue_date?: string;
    /**
     * Additional note for the invoice
     */
    note?: string;
}

