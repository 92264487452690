/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PayoutStatus } from './payoutStatus';
import { TransactionStatus } from './transactionStatus';
import { TransactionProductResource } from './transactionProductResource';


/**
 * Transaction resource
 */
export interface TransactionResource { 
    /**
     * ID
     */
    id?: number;
    /**
     * UID
     */
    uuid?: string;
    transaction_status?: TransactionStatus;
    /**
     * Transaction type
     */
    transaction_type?: number;
    /**
     * Transaction timestamp
     */
    transaction_timestamp?: string;
    /**
     * Customer first name
     */
    first_name?: string;
    /**
     * Customer email
     */
    email?: string;
    /**
     * Merchant paymentwall transaction ID
     */
    merchant_paymentwall_order_id?: string | null;
    /**
     * Transaction price
     */
    price?: number;
    /**
     * Transaction total fee gross
     */
    total_fee_gross?: number;
    /**
     * Transaction total fee net
     */
    total_fee_net?: number;
    /**
     * Transaction total fee vat
     */
    total_fee_vat?: number;
    payout_status?: PayoutStatus;
    /**
     * Timestamp of the payout
     */
    payout_timestamp?: string;
    /**
     * Amount of the payout
     */
    payout_amount?: number;
    /**
     * Timestamp of when the transaction was accounted
     */
    accounted_timestamp?: string;
    /**
     * Current balance after the Transaction
     */
    balance?: number;
    /**
     * Refund Amount
     */
    refund_amount?: number;
    /**
     * Timestamp of when the transaction was refunded
     */
    refund_timestamp?: string;
    /**
     * Products associated with the transaction
     */
    transaction_products?: Array<TransactionProductResource>;
}
export namespace TransactionResource {
}


