/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WithdrawalStatus } from './withdrawalStatus';


/**
 * Biling status resource
 */
export interface BillingStatusResource { 
    /**
     * Total amount
     */
    totalAmount?: number;
    /**
     * Total charges
     */
    totalCharges?: number;
    /**
     * Withdrawal amount
     */
    withdrawalAmount?: number;
    /**
     * Date of withdrawal
     */
    withdrawalDate?: string;
    withdrawalStatus?: WithdrawalStatus;
    /**
     * Date of settlement
     */
    settlementDate?: string;
}
export namespace BillingStatusResource {
}


