import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { UpperCasePipe, CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  SharedServicesModule,
  AuthService,
  ErrorInterceptor,
} from '@eself/shared-services';
import { SharedUiModule } from '@eself/shared-ui';
import { NgxEchartsModule } from 'ngx-echarts';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { ApiModule, Configuration } from 'b2b-api-client';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedUiModule,
    SharedServicesModule,
    HttpClientModule,
    ApiModule,
    ReactiveFormsModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    UpperCasePipe,
    CurrencyPipe,
    {
      provide: Configuration,
      useFactory: (authService: AuthService) =>
        new Configuration({
          basePath: environment.apiUrl,
          credentials: {
            api: () => authService.getAccessToken(),
          },
        }),
      deps: [AuthService],
      multi: false,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
