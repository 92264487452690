/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 1 - Individual, 2 - OnePersonBusiness, 3 - CivilPartnership, 4 - GeneralPartnership, 5 - LimitedPartnership, 6 - LimitedJointStockPartnership, 7 - JointStockCompany, 8 - LimitedLiabilityCompany, 9 - AssociationFoundationNonProfitOrganization, 10 - Cooperative
 */
export type ShopType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const ShopType = {
    NUMBER_1: 1 as ShopType,
    NUMBER_2: 2 as ShopType,
    NUMBER_3: 3 as ShopType,
    NUMBER_4: 4 as ShopType,
    NUMBER_5: 5 as ShopType,
    NUMBER_6: 6 as ShopType,
    NUMBER_7: 7 as ShopType,
    NUMBER_8: 8 as ShopType,
    NUMBER_9: 9 as ShopType,
    NUMBER_10: 10 as ShopType
};

