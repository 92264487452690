/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SettlementResource } from './settlementResource';
import { InvoiceStatus } from './invoiceStatus';


/**
 * Invoice resource
 */
export interface InvoiceResource { 
    /**
     * ID
     */
    id?: number;
    /**
     * Number of the invoice
     */
    invoice_number?: string;
    /**
     * Date when the invoice was issued
     */
    issue_date?: string;
    status?: InvoiceStatus;
    /**
     * Additional notes for the invoice
     */
    note?: string;
    /**
     * Number of transactions associated with the invoice
     */
    transaction_count?: number;
    /**
     * Date of the first transaction
     */
    first_transaction_date?: string;
    /**
     * Date of the last transaction
     */
    last_transaction_date?: string;
    /**
     * eSelf fee in gross
     */
    only_eself_fee_gross?: number;
    /**
     * eSelf fee in net
     */
    only_eself_fee_net?: number;
    /**
     * eSelf fee VAT
     */
    only_eself_fee_vat?: number;
    /**
     * Settlements associated with the invoice
     */
    settlements?: Array<SettlementResource>;
}
export namespace InvoiceResource {
}


