/**
 * E-SELF API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 1 - Pending, 2 - Realized
 */
export type RefundStatus = 1 | 2;

export const RefundStatus = {
    NUMBER_1: 1 as RefundStatus,
    NUMBER_2: 2 as RefundStatus
};

